import React, { useState, useRef, useCallback, useEffect } from "react"

import ChartEarnings from "./ChartEarnings"
import "./chartStyle.module.scss"
import ChartProduct from "./ChartProduct"

const ListChart = ({ valueFilter }) => {
  const [currentTab, setcurrentTab] = useState("1")

  const Chart1Ref = useRef(null)
  const Chart2Ref = useRef(null)
  // const onChange = (key) => {
  //   console.log(key);
  // };
  // const onChangeDateBefor = (date, dateString) => {
  //   console.log(date, dateString);
  // };
  // const onChangeDateAffter = (date, dateString) => {
  //   console.log(date, dateString);
  // };

  const handleScollTo = (tabNumber) => {
    setcurrentTab(tabNumber)
    switch (tabNumber) {
      case "1":
        Chart1Ref.current?.scrollIntoView({ behavior: "smooth", block: "end" })
        break
      case "2":
        Chart2Ref.current?.scrollIntoView({ behavior: "smooth" })
        break
      // case "4":
      //   chart4Ref.current?.scrollIntoView({ behavior: "smooth" });
      //   break;
      // case "5":
      //   chart3Ref.current?.scrollIntoView({ behavior: "smooth" });
      //   break;
    }
  }

  return (
    <div className="bg-[#FFFFFF]">
      <div className="flex justify-center">
        <div className="w-[100%]">
          <div className="ml-[10px] mt-[10px]">
            <span
              className={
                currentTab === "1"
                  ? `tabHeader tabHeaderHover aroundBlockBoder`
                  : `tabHeader`
              }
              onClick={() => {
                handleScollTo("1")
              }}
            >
              売上
            </span>
            <span
              className={
                currentTab === "2"
                  ? `tabHeader tabHeaderHover aroundBlockBoder`
                  : `tabHeader`
              }
              onClick={() => {
                handleScollTo("2")
              }}
            >
              受注
            </span>
          </div>

          <div className="w-[100%] mt-[6px] px-[20px] bg-[#FAFAFA] py-[30px]">
            <div ref={Chart1Ref} className="bg-[#fff] p-[30px]">
              <ChartEarnings valueFilter={valueFilter} />
            </div>
            <div ref={Chart2Ref} className="bg-[#fff] mt-[40px] p-[30px]">
              <ChartProduct valueFilter={valueFilter} />
            </div>
            {/* <div ref={Chart2Ref}>
              <Chart2></Chart2>
            </div>
            <div ref={chart3Ref}>
              <Chart3></Chart3>
            </div>
            <div ref={chart4Ref}>
              <Chart4></Chart4>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListChart
