import {
  Checkbox,
  Form,
  Typography,
  Divider,
  Select,
  Space,
  Empty,
  Input,
  Radio,
  DatePicker,
  Button,
  Spin,
} from "antd"
import { debounce } from "lodash"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { OverViewContext } from ".."
import { getListCompany } from "../../../services/superAdManageCompany"

import "./style.scss"
const { RangePicker } = DatePicker
const dateFormat = "YYYY/MM/DD"

const { Text } = Typography
const optionStatus = [
  {
    label: "すべて",
    value: "すべて",
  },
  {
    label: "決済済み",
    value: "決済済み",
  },
  {
    label: "発送済み",
    value: "発送済み",
  },
  {
    label: "納品済み",
    value: "納品済み",
  },
]
const optionFormat = [
  {
    label: "すべて",
    value: "すべて",
  },
  {
    label: "飲食店",
    value: "飲食店",
  },
  {
    label: "小売店",
    value: "小売店",
  },
  {
    label: "メーカー",
    value: "メーカー",
  },
  {
    label: "生産者",
    value: "生産者",
  },
]
const monthFormat = "YYYY/MM"

const Filter = ({ debounceTimeout = 500 }) => {
  const { valueFilter, setValueFilter } = useContext(OverViewContext)
  const [pickerType, setPickerType] = useState("day")

  const [listCompany, setListCompany] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const fetchRef = useRef(0)

  const [haveMore, setHaveMore] = useState(true)
  const [searchParams, setSearchParams] = useState(null)

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const [form] = Form.useForm()
  const handlegetListCompany = async () => {
    setIsLoading(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,

      q: searchParams ? searchParams : null,
    }
    const { response } = await getListCompany(dataSent)
    if (response.status == 200) {
      setListCompany(response.data.stores)
      if (response.data.stores?.length < 10) {
        setHaveMore(false)
      }
      if (response.data.stores?.length == 10) {
        setHaveMore(true)
      }
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handlegetListCompany()
  }, [searchParams])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }
      setIsLoading(true)

      setListCompany([])
      tableParams.pagination.current = 1
      setTableParams({ ...tableParams })
      setSearchParams(value)
    }
    return debounce(loadOptions, debounceTimeout)
  }, [handlegetListCompany, debounceTimeout])

  const onScroll = async (event) => {
    if (!haveMore) {
      return
    }
    const target = event.target
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      console.log("load")

      target.scrollTo(0, target.scrollHeight)

      tableParams.pagination.current = tableParams.pagination.current + 1
      setIsLoading(true)

      setTableParams({ ...tableParams })

      const dataSent = {
        offset:
          (tableParams.pagination.current - 1) *
          tableParams.pagination?.pageSize,
        limit: tableParams.pagination.pageSize,

        q: searchParams ? searchParams : null,
      }
      const { response } = await getListCompany(dataSent)
      if (response.status == 200) {
        if (response.data.stores?.length < 10) {
          setHaveMore(false)
        }
        if (response.data.stores?.length == 10) {
          setHaveMore(true)
        }

        setListCompany([...listCompany, ...response.data.stores])
        setIsLoading(false)
      }
    }
  }
  const onFinish = (value) => {
    console.log("valuevaluevalue", value)
    setValueFilter(value)
  }
  console.log(listCompany)
  return (
    <div className=" pb-4 px-5 filterSuperOverView">
      <Text className="!text-[#9B9B9B] text-sm font-medium">絞り込み条件</Text>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        form={form}
        onFinish={onFinish}
        initialValues={valueFilter}
      >
        <Form.Item label={<Text>企業名</Text>} name="store_id">
          <Select
            allowClear
            className="fix_antd_remove_selection"
            placeholder="出品企業を選択してください"
            showSearch={true}
            filterOption={(input, option) => option}
            onSearch={debounceFetcher}
            onPopupScroll={onScroll}
            onBlur={() => {
              tableParams.pagination.current = 1
              setTableParams({ ...tableParams })
              setSearchParams(null)
            }}
          >
            {isLoading
              ? [
                  ...listCompany.map((great) => (
                    <Select.Option value={great.store_id} key={great.id}>
                      {great.name}
                    </Select.Option>
                  )),
                  <Select.Option key="loading" disabled={true}>
                    <Spin size="small" />
                  </Select.Option>,
                ]
              : listCompany.map((great) => (
                  <Select.Option value={great.store_id} key={great.id}>
                    {great.name}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
        <Divider />

        <Form.Item label={<Text>期間選択</Text>}>
          <Space>
            <Form.Item name="dateFilter">
              <RangePicker picker={pickerType} allowClear={false} />
            </Form.Item>
            <Form.Item name="typeFilter">
              <Radio.Group
                onChange={(e) => {
                  if (e.target.value) {
                    setPickerType(e.target.value)
                  }
                }}
              >
                <Radio value="day">{"日ごと表示"}</Radio>
                <Radio value="month">{"月ごと表示"}</Radio>
              </Radio.Group>
            </Form.Item>
          </Space>
        </Form.Item>
        <div className="text-center mt-[26px]">
          <Button type="primary" htmlType="submit" className="!w-[141px]">
            検索
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default Filter
