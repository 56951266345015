import { Breadcrumb, Spin, Typography } from "antd"
import { Link } from "gatsby"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import { getDate } from "../../customUtil/global"

import Filter from "./Filter"
import ListChart from "./ListChart"
import { Content } from "antd/lib/layout/layout"

const { Title } = Typography
const dateFormat = "YYYY/MM/DD"

export const OverViewContext = React.createContext()

const SuperOverView = () => {
  const [valueFilter, setValueFilter] = useState({
    dateFilter: [
      moment(getDate(30), dateFormat),
      moment(getDate(), dateFormat),
    ],
    typeFilter: "day",
  })

  const values = {
    valueFilter,
    setValueFilter,
  }

  return (
    <OverViewContext.Provider value={values}>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          {" "}
          <Breadcrumb separator={">"} className="tracking-[0.1em]">
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="!text-grey-4">売上管理</Breadcrumb.Item>
          </Breadcrumb>
          <Title
            level={2}
            className="!text-2xl  !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
          >
            売上管理
          </Title>
        </div>
        <Filter />
        <ListChart valueFilter={valueFilter} />
      </Content>
    </OverViewContext.Provider>
  )
}

export default SuperOverView
